import React from "react";
import { graphql } from "gatsby";
import { Container, ConvertedMarkdown } from "../components";
import { PageNotFoundStyles } from "../styles/PageNotFoundStyles";

const PageNotFound = ({ data }) => {
  const dataQuery = data.allMarkdownRemark.nodes[0],
    componentID = data.allMarkdownRemark.nodes[0].frontmatter.components_id,
    markdownHTML = data.allMarkdownRemark.nodes[0].html,
    markdownID = `pagenotfound-content`;

  return (
    <>
      <Container
        markdownID={markdownID}
        componentID={componentID}
        query={dataQuery}
        noSubNav={true}
      >
        <PageNotFoundStyles id={`main`} className={`wrapper`}>
          <ConvertedMarkdown mdhtml={markdownHTML} />
        </PageNotFoundStyles>
      </Container>
    </>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { markdown_id: { eq: "pagenotfound-content" } } }
    ) {
      nodes {
        frontmatter {
          slug
          description
          keywords
          markdown_id
          title
          components_id
        }
        html
      }
    }
  }
`;

export default PageNotFound;
