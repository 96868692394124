import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const PageNotFoundStyles = styled.main`
  margin-top: 4rem !important;
  padding-bottom: 7.5rem !important;
  @media ${device.mobile} {
    padding-top: 11rem !important;
    padding-bottom: 4rem !important;
  }
  .md-container {
    padding-top: 2.5rem;
    @media ${device.mobile} {
      padding-top: 0rem !important;
    }
  }
  .error-page {
    text-align: center;

    h1,
    h2,
    p {
      margin-bottom: 1.25rem;
    }

    .button-container {
      max-width: 120px;
      margin: 2.5rem auto 0 auto;
      @media ${device.mobile} {
        max-width: 90px;
      }
      .btn-1 {
        padding: 1.5rem;
        & > img {
          width: 0.8rem;
          position: absolute;
          right: 0;
          top: 30%;
          margin-right: 0.75rem;
          height: auto;
        }
      }
    }
  }
`;
